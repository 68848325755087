import React from "react";
import style from "./style.module.css";
import Header from "../../components/molecules/Header";

const Home = () => (
    <div className={style.container}>
        <Header />
        <div className={style.content}>
            <blockquote>
                <p>
                    O correr da vida embrulha tudo. <br />
                    A vida é assim: esquenta e esfria, <br />
                    aperta e daí afrouxa, <br />
                    sossega e depois desinquieta. <br />O que ela quer da gente
                    é coragem.
                </p>
                <footer>
                    Guimarães Rosa, <cite>Grande Sertão: Veredas</cite>
                </footer>
            </blockquote>
        </div>
    </div>
);

export default Home;
