import { useState, useEffect } from "react";
import style from "./style.module.css";

const Toast = (props) => {
  const {
    message,
    backgroundColor,
    timeout = 3000,
    showToast = false,
    onToastChange = () => {},
  } = props;
  const [show, setShowToast] = useState(showToast);

  useEffect(() => {
    setShowToast(showToast);
  }, [showToast]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowToast(false);
    }, timeout);

    return () => {
      clearInterval(interval);
    };
  }, [timeout]);

  useEffect(() => {
    onToastChange(show);
  }, [show, onToastChange]);

  return (
    <>
      {show && (
        <div className={style.Toast} style={{ backgroundColor }}>
          {message}
        </div>
      )}
    </>
  );
};

export default Toast;
