import React from "react";
import style from "./style.module.css";
import Header from "../../components/molecules/Header";
import Avatar from "../../components/atoms/Avatar";
import Typewriter from "typewriter-effect";
import shuffle from "../../utils/shuffle";
const messages = require("../../data/messages.json");
const shuffledMessages = shuffle(messages)

const About = () => (
    <div className={style.container}>
        <Header />
        <div className={style.content}>
            <section className={style.section}>
                <Avatar 
                    src={require("../../assets/avatar.png")}
                    alt="foto do autor do site, usando uma máscara preta e com uma das mãos atrás da cabeça"
                    className={"text-align=center;"}
                />
                <h1 className={style.name}>Vagner Clementino</h1>
                <div className={style.about}>
                    Eu 
                    <span>
                        <label className={style.label}>
                            <Typewriter
                                options={{
                                    strings: shuffledMessages,
                                    autoStart: true,
                                    loop: true,
                                }}
                                onInit={(typewriter)=> {
                                    typewriter
                                    .pauseFor(1000)
                                    .deleteAll()
                                    .start();
                                }}
                            />
                        </label>
                    </span>
                </div>
                <a href={require("../../assets/cv.pdf")}
                   className={style.button}
                   target="_blank"
                   rel="noopener noreferrer"
                   >
                    Download CV
                </a>
            </section>
        </div>
    </div>
);

export default About;
