import React from "react";
import style from "./style.module.css";
import JavaOriginalIcon from "react-devicons/java/original";
import JavascriptOriginalIcon from "react-devicons/javascript/original";
import PythonOriginalIcon from "react-devicons/python/original";
import {faCode} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ProjectCard = (data) => {
  const { data: project } = data;
  const getLanguageIcon = (language, size = "2em") => {
    const devIcons = {
      javascript: <JavascriptOriginalIcon size={size} />,
      python: <PythonOriginalIcon size={size} />,
      java: <JavaOriginalIcon size={size} />,
    };

    return (
      devIcons[language.toLowerCase()] || <FontAwesomeIcon icon={faCode} size="2xl" />
    );
  };

  const addEllipsisDescription = (str, limit) => {
    if (str.length <= limit) {
      return str;
    }
    const truncatedStr = str.slice(0, limit);
    const lastWord = truncatedStr.trim().split(" ").pop();

    let addedEllipsis = truncatedStr + "...";

    if (lastWord && truncatedStr.endsWith(lastWord)) {
      addedEllipsis =
        truncatedStr.slice(0, truncatedStr.length - lastWord.length) + "...";
    }

    return addedEllipsis;
  };

  return (
    <div className={style.card} data-testid="project-card">
      <div className={style.card__content}>
        <div className={style.card__header}>
          <a href={project.html_url} target="_blank" rel="noopener noreferrer">
            <h1>{project.name}</h1>
          </a>
        </div>
        <div className={style.card__body}>
          <p>{addEllipsisDescription((project.description || ""), 120)}</p>
          {getLanguageIcon(project.language || "")}
          <p>Stars: {project.stargazers_count}</p>
        </div>
        <div>
          <div className={style.card__footer}>
            <a
              href={`${project.html_url}#readme`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Saiba mais
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
