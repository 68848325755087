const shuffle = (value) => {
	const array = Array.isArray(value) ? value : undefined;
	if (array) {
		const shuffled = [...array]
		for (let i = shuffled.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
		}
		return shuffled;

	}
	return array;
}

export default shuffle;