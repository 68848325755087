import React from 'react';
import PropTypes from 'prop-types';
import style from "./style.module.css";

const Image = ({ alt, className, circle, size, onClick, ...props }) => (
  <img
    alt={alt}
    className={`${circle ? style.circle : ''} ${style[size]} ${className}`}
    onClick={onClick}
    data-testid="image"
     {...props}
  />
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  circle: PropTypes.bool,
  onClick: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Image.defaultProps = {
  className: '',
  circle: false,
};

export default Image;
