import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import Header from "../../components/molecules/Header";
import axios from "axios";
import ProjectCard from "../../components/molecules/ProjectCard";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.github.com/users/vagnerclementino/repos?sort=updated&order=desc"
        );
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className={style.container}>
      <Header />
      <div className={style.content}>
        <div className={style.card_layout}>
          {projects
            .filter((project) => project.fork === false && project.archived === false)
            .slice(0, 12)
            .map((project) => (
              <div key={project.id} className={style.card_layout__item}>
                <ProjectCard data={project} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
