import React from "react";
import style from "./style.module.css";
import Header from "../../components/molecules/Header";
import IframeResizer from 'iframe-resizer-react'

const blogURL = process.env.REACT_APP_BLOG_URL || "https://clementino-notes.netlify.app/blog"

const Blog = () => (
    <div className={style.container}>
        <Header />
        <div className={style.content}>
                <IframeResizer 
                    heightCalculationMethod="lowestElement"
                    inPageLinks
                    log
                    title="blog"
                    scrolling="true"
                    loading="lazy"
                    aria-hidden="true"
                    className={style.iframe} 
                    src={blogURL}
                />
        </div>
    </div>
);

export default Blog;
