import React from 'react';
import { useHistory } from 'react-router-dom';
import style from "./style.module.css";
import Image from "../Image";
import logo from "../../../assets/logo.svg";

const Logo = () => {
   const history = useHistory();
   return <Image
    src={logo}
    className={style.Logo}
    alt="This is website logo. If you clicks here, will be redirected to homepage."
    onClick={() => { 
        history.push('/');
      }}
  />
}

export default Logo;