import React from 'react';
import PropTypes from 'prop-types';
import style from "./style.module.css";

const Avatar = ({ alt, src, className, onClick, ...props }) => (
  <div className={`${style.avatar} ${className}`} data-testid="avatar">
    <img 
	alt={alt}
	className={style.image}
	src={src}
	{...props}
    />
 </div>
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.any,
};

Image.defaultProps = {
  className: '',
};

export default Avatar;
